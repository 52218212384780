import logo from ".././assets/Biorbank.svg";
import polygon2 from ".././assets/Polygon 2.svg";
import polygon3 from ".././assets/Polygon 3.svg";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import responsive from ".././assets/responsive.svg";
import { setSidebarMode, setSidebarShow } from "../redux/Sidebar";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="w-full sm:px-[60px] px-[16px] h-[80px] justify-between flex items-center z-[100] fixed bg-white overflow-hidden">
        <img
          src={logo}
          alt=""
          className="cursor-pointer"
          onClick={() => navigate("/")}
        />
        <div className="items-center gap-[25px] text-[#070623] text-[14px] sm:flex hidden">
          <a href="mailto:ContactUs@biorbank.com">
            <div className="cursor-pointer hover:text-[#a0a0a0]">
              Contact Us
            </div>
          </a>
          <div
            className="cursor-pointer hover:text-[#a0a0a0]"
            onClick={() => {
              document
                .getElementById("aboutUs")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            About Us
          </div>
          {/* <a
            href="https://drive.google.com/file/d/1lKvfGSW-d-enogLV7XWoVsQ2BndjMcep/view?usp=sharing"
            target="blank"
          >
            <div className="cursor-pointer hover:text-[#a0a0a0]">Litepaper</div>
          </a> */}
          {/* <div
            className="cursor-pointer hover:text-[#a0a0a0]"
            onClick={() => {
              navigate("/merch/track");
            }}
          >
            Track Order
          </div>
          <div
            className="px-[14px] py-[10px] rounded-full bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
            // data-tooltip-id="tooltip"
            onClick={() => navigate("/merch")}
          >
            Buy Merch
          </div> */}
        </div>
        <img
          src={responsive}
          alt=""
          className="sm:hidden block cursor-pointer"
          onClick={() => {
            dispatch(setSidebarMode(null));
            dispatch(setSidebarShow(true));
          }}
        />
      </div>
      <>
        <img
          src={polygon2}
          alt=""
          className="sm:top-0 absolute top-[-4vh] sm:left-0 left-[-70vw]"
        />
        <img
          src={polygon3}
          alt=""
          className="sm:top-0 absolute top-[-4vh] sm:right-0 right-[-70vw]"
        />
      </>
      <Outlet />
    </>
  );
};
